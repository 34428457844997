.carousel .control-dots .dot.selected {
  background: #ffff49 !important;
}

.carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 0.4;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 30%) !important;
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slider {
  padding-bottom: 50px;
}

* {
  cursor: none;
}